import React from 'react'
import Layout from '../layouts/simple.js'
import { Helmet } from 'react-helmet'

const Error = () => {
    return (
        <div className="page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dundas Interiors - 404 This page no longer exists.</title>
                <link rel="canonical" href="dundasinteriors.com" />
            </Helmet>
            <Layout>
                <h2 id="error-message">404 - Sorry, The Page You Were Looking For Does Not Exist.</h2>
            </Layout>
        </div>
    )
}

export default Error